import JudgeType from "@/models/sporteventCalculation/JudgeType";
import BaseService from "../BaseService";

class JudgeTypeService extends BaseService<JudgeType> {
  public url: string;

  constructor(url = "sportevent-calculation/judgetype/") {
    super(url, "");
    this.url = url;
  }
}
export default new JudgeTypeService();
